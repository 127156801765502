// ============================ //
// ======== VARIABLES ========= //
// ============================ //

// Colors -----------
$color-main:       #0028be;
$color-secondary:  #b0c0ea;
// ------------------

// Typo / fonts -----
$font-main:       'Poppins', sans-serif;
$font-secondary:  'Barlow Condensed', sans-serif;

$title-h1:        36px;
$title-h2:        24px;
$title-font:      $font-secondary;

$text-size:       18px;
$text-small:      12px;
$text-font:       $font-main;
// ------------------
