// ======================== //
// ======== FORM  ========= //
// ======================== //

input {
  display: none;
}

#fileUploaderArea,
#conformityCheckerArea {
  height: 200px;
}

#fileUploaderArea>.card,
#conformityCheckerArea>.card {
  padding: 0;
  height: 100%;
  max-width: unset;
  cursor: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conformityCheckerTable {
  color: $color-main;
  width: 90%;
  height: 90%;
  font-size: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.conformityCheckerTable>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableFirstRow {
  background-color: #b0c0ea;
}

.specNameFirstRow {
  grid-area: 1 / 1 / 1 / last-column;
}

label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.selected,
.highlight {
  background-position: -183%;
  color: #F2F2F2;
  cursor: default;
}
