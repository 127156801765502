// ======================== //
// ======== BASE  ========= //
// ======================== //

body {
  margin:         0 0 50px 0;
  padding:        0;
  border:         0;
  box-sizing:     border-box;
  font-family:    $font-main;
  font-size:      24px;
  background:     #F2F2F2;
  color:          $color-main;
}

[data-class="loader"] {
  position:           fixed;
  top:                0;
  left:               0;
  width:              100vw;
  height:             100vh;
  background:         repeating-linear-gradient(107deg, #F2F2F2 0 20px, #b0c0ea 20px 40px);
  z-index:            1;
  display:            flex;
  justify-content:    center;
  align-items:        center;
  flex-direction:     column;
  background-repeat:  repeat-x;
  background-size:    400% 100%;
  animation:          loaderAnimation 500s linear infinite;
}

@keyframes loaderAnimation {
  0% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.loaderCircle {
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 150px;
  padding: 22.5px;
  aspect-ratio: 1;
  flex-direction: column;
}

.loaderText {
  font-size: 18px;
  margin-top: -50px;
  font-family: $font-secondary;
  color: $color-main;
}

.loaded {
  display: none !important;
}


nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding: 20px;
  margin: 0 0 30px 0;
  height: 62.63px;
  background-color: $color-main;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  font-family: $title-font;
  color: #FFF;
  font-size: $title-h1;
  margin: 0;
  text-align: center;
}

.card {
  width: 100%;
  height: 100%;
  border: solid 2px $color-main;
  border-radius: 5px;
  padding: 5px 5px;
  text-align: center;
  max-width: calc(25% - 25px);
  min-width: 150px;
  cursor: pointer;
  font-size: 18px;
  color: $color-main;
  background: linear-gradient(107deg, $color-main 0 50%, #F2F2F2 50% 0);
  background-size: 220%;
  background-position: -84%;
  transition: all 1000ms;
}

#languageSelector {
  height: unset;
  width: 100%;
  font-size: 18px;
  max-width: 110px;
  min-width: unset;
  justify-self: center;
  align-self: center;
  font-size: 12px;
  border: 0;
  text-decoration: underline;
  background: $color-main;
  color: #FFF;
}

section {
  margin: 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: $title-font;
  font-size: $title-h2;
  color: $color-main;
  margin: 30px 0 10px 0;
}

.zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.module {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 18px;
  flex-wrap: wrap;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.gallery {
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gallery>div {
  height: 100%;
}

@media screen and (max-width: 640px) {
  section {
    margin: 0 10px;
  }
  .card {
    max-width: unset;
    min-width: unset;
  }
  .columns {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  h1 {}
  nav {
    flex-direction: column;
    height: unset;
  }
  nav>div {
    margin-bottom: 15px;
  }
}
