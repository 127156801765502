// ========================= //
// ======== ZONES  ========= //
// ======================== //

.safeZone {
  position:   relative;
  opacity:    0;
  visibility: hidden;

  &:before {
    content:        "Zone de sécurité / Safe Zone";
    position:       absolute;
    text-transform: uppercase;
    top:            20px;
    right:          20px;
    color:          #018001;
    font-size:      52px;
    font-family:    $font-secondary;
    text-shadow:    0px 0px 12px #FFFFFF;
  }
}

.contentZone {
  position:   relative;
  opacity:    0;
  visibility: hidden;

  &:before {
    content:        "Contenu du Site / Site Content";
    position:       absolute;
    text-transform: uppercase;
    top:            20px;
    right:          20px;
    color:          red;
    font-size:      52px;
    font-family:    $font-secondary;
    text-shadow:    0px 0px 12px #000000;
  }
}

.safeZoneHeightStyle {
  &:before {
    content:        "Ligne de flottaison / above the fold";
    position:       absolute;
    text-transform: uppercase;
    top:            20px;
    right:          20px;
    color:          blue;
    font-size:      52px;
    font-family:    $font-secondary;
    text-shadow:    0px 0px 12px #FFFFFF;
  }
}
